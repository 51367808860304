<template>
  <div class="map-filter-box" v-if="!showId">
    <div class="filter-top">
      <span class="filter-title">含山县2024年含山大米绿色生产基地范围统计</span>
      <div class="total">
        <span>基地总面积：</span>
        <div class="content">140181.86亩</div>
      </div>
      <div class="total">
        <span>主体数量：</span>
        <div class="content">373</div>
      </div>
    </div>
    <div class="map-filter">
      <div class="filter-label">
        <span class="filter-title">镇</span>
        <el-select
          v-model="town_id"
          placeholder="请选择镇"
          v-loadMore="loadMoreTownData"
          @change="changeTown"
        >
          <el-option
            :label="item.town_name"
            :value="item.town_id"
            v-for="(item, index) in townList"
            :key="index"
          ></el-option>
        </el-select>
      </div>
      <div class="filter-label">
        <span class="filter-title">核心片区</span>
        <el-select
          v-model="scope_id"
          placeholder="请选择片区"
          v-loadMore="loadMoreScopeData"
          @change="changeScope"
        >
          <el-option
            :label="item.scope_name"
            :value="item.scope_id"
            v-for="(item, index) in scopeList"
            :key="index"
          ></el-option>
        </el-select>
      </div>
      <div class="filter-label">
        <span class="filter-title">涉及村名</span>
        <p class="village-p">
          <span v-for="(item, index) in villageList" :key="index">{{
            item.village_name
          }}</span>
        </p>
      </div>
      <div class="filter-label">
        <span class="filter-title">面积</span>
        <div class="filter-main one-txt-cut">{{ detail.area }}亩</div>
      </div>
      <div class="filter-label">
        <span class="filter-title">主体数量</span>
        <div class="filter-main nomargin">{{ detail.count }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getTownListApi,
  getScopeListApi,
  getVillageListApi,
  getStatisticApi,
} from "@/request/api";
import { mapState } from "vuex";
export default {
  name: "mapFilter",
  props: {
    showId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      townTotal: 0,
      townPs: 10,
      townPn: 1,
      town_id: "",
      townList: [],

      scopeList: [],
      scopeTotal: 0,
      scopePs: 10,
      scopePn: 1,
      scope_id: "",

      villageList: [],
      village_id: "",
      detail: { count: 0, area: 0 },
    };
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
    }),
  },
  created() {
    this.getTownListApi();
  },
  methods: {
    changeTown() {
      this.scopeList = [];
      this.villageList = [];
      this.village_id = "";
      this.scope_id = "";
      this.villagePn = 1;
      this.scopePn = 1;
      this.detail = { count: 0, area: 0 };
      this.getScopeListApi();
    },
    async changeScope() {
      this.villageList = [];
      this.village_id = "";
      this.detail = { count: 0, area: 0 };
      this.villagePn = 1;
      this.$emit("changeVillage", this.town_id, this.scope_id);
      this.getVillageListApi();
      const { code, results } = await getStatisticApi({
        town_id: this.town_id,
        scope_id: this.scope_id,
      });
      if (code != 0) return;
      this.detail = results;
    },
    async getVillageListApi(pn = 1) {
      const { code, results } = await getVillageListApi({
        pn,
        ps: 100,
        scope_id: this.scope_id,
      });
      if (code !== 0) return;
      this.villageList = results.data;
    },
    async getScopeListApi(pn = 1) {
      const { code, results } = await getScopeListApi({
        town_id: this.town_id,
        pn,
      });
      if (code != 0) return;
      this.scopeTotal = results.count;
      this.scopePs = results.ps;
      this.scopePn = results.pn;
      this.scopeList = [...this.scopeList, ...results.data];
    },
    async getTownListApi() {
      const { code, results } = await getTownListApi({
        manor_id: this.farmID,
        ps: this.ps,
        pn: this.pn,
      });
      if (code !== 0) return;
      this.townTotal = results.count;
      this.townPs = results.ps;
      this.townPn = results.pn;
      this.townList = [...this.townList, ...results.data];
    },
    loadMoreTownData() {
      if (this.townList.length && this.townList.length == this.townTotal)
        return;
      this.townPn++;
      this.getTownListApi(this.townPn);
    },
    loadMoreScopeData() {
      if (this.scopeList.length && this.scopeList.length == this.scopeTotal)
        return;
      this.scopePn++;
      this.getScopeListApi(this.scopePn);
    },
  },
};
</script>

<style lang="less" scoped>
.map-filter-box {
  width: 1069px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 140px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  .filter-top {
    height: 36px;
    width: 100%;
    padding: 0 20px;
    background-color: #155ca5;
    align-items: center;
    display: flex;
    border-radius: 10px 10px 0 0;
    color: #fff;
    font-size: 16px;
    .filter-title {
      margin-right: 60px;
    }
    .total {
      display: flex;
      align-items: center;
      margin-right: 30px;

      span {
      }
      .content {
        background-color: #fff;
        border-radius: 5px;
        color: #155ca5;
        padding: 0 10px;
      }
    }
  }
  .map-filter {
    width: 100%;
    height: 72px;
    background: #142d4c;
    padding: 0 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .filter-label {
      display: flex;
      align-items: center;
      .filter-title {
        margin-right: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
      }
      .village-p {
        height: 100%;
        width: 240px;
        padding: 10px 0;
        span {
          font-size: 14px;
          font-weight: 400;
          color: #ccc;
          margin-right: 4px;
        }
      }
      /deep/.el-select {
        width: 134px;
        height: 40px;
        border-radius: 3px;
        margin-right: 25px;
        .el-input__inner {
          border: 1px solid #385576;
          color: #fff;
          background: #081c30;
        }
      }
      .filter-main {
        min-width: 39px;
        max-width: 74px;
        height: 40px;
        background: #15589d;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        line-height: 40px;
        padding: 0 16px;
        margin-right: 25px;
        text-align: center;
        &.nomargin {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
